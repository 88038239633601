<template>
    <page-content title="报警记录">

        <common-table
                ref="table"
                path="web/iot/water/alarm"
                date-range
                :queryParams="queryParams"
                :columns="columns">

            <template slot="search">
                <a-button @click="$refs.table.export('报警记录')">导出Excel</a-button>
            </template>

            <template slot="type" slot-scope="{record}">
                <a-tag v-if="record.type==1" color="gray">低于最小值</a-tag>
                <a-tag v-else color="cyan">高于最大值</a-tag>
            </template>

        </common-table>


    </page-content>
</template>
<script>
    export default {

        data () {
            return {
                queryParams:{
                    deviceId:this.$route.params.deviceId
                }
            }
        },

        computed: {
            columns () {
                return [
                    {
                        title: '时间',
                        dataIndex: 'createdAt',
                        sorter:true
                    },
                    {
                        title: '报警数据',
                        dataIndex: 'identifier',
                        filters: [
                            {text: '全部', value: ""},
                            {text: 'PH', value: 'reg5'},
                            {text: 'ORP', value: 'reg4'},
                            {text: '余氯', value: 'reg6'},
                            {text: '温度', value: 'reg3'},
                            {text: '浊度', value: 'reg7'},
                            {text: '尿素', value: 'reg8'}
                        ],
                        filterMultiple: false,
                        customRender(text,record){
                            return record.paraName
                        }
                    },
                    {
                        title: '最小值',
                        dataIndex: 'min',
                    },
                    {
                        title: '最大值',
                        dataIndex: 'max',
                    },
                    {
                        title: '当前值',
                        dataIndex: 'currentValue',
                        sorter:true
                    },
                    {
                        title: '报警类型',
                        dataIndex: 'type',
                        scopedSlots: {customRender: 'type'},
                        filters: [
                          {text: '全部', value: ""},
                          {text: '低于最小值', value: '1'},
                          {text: '高于最大值', value: '2'}
                        ],
                        filterMultiple: false,
                    },
                    {
                        title: '偏移量',
                        dataIndex: 'offsetValue',
                        sorter:true
                    },
                ]
            }
        },
        methods: {
            getList () {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

</style>
